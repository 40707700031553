import React from "react";
import { projects } from "../../data/constants";
import {
  Container,
  Wrapper,
  Title,
  Description,
  CardContainer,
} from "./styledComponents";
import SingleCard from "../cards/projectCard";

const Projects = () => {
  return (
    <Container id="projects">
      <Wrapper>
        <Title>Projects</Title>
        <Description>
          I have worked on a wide range of projects . Here are some of my
          projects.
        </Description>
        <CardContainer>
          {projects.map((project, index) => {
            return <SingleCard project={project} index={index} />;
          })}
        </CardContainer>
      </Wrapper>
    </Container>
  );
};

export default Projects;
