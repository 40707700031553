import styled from "styled-components";
import { useState } from "react";

const SingleCard = ({ project, index }) => {
  const Card = styled.div`
    width: 330px;
    height: fit-content;
    background-color: ${({ theme }) => theme.card};
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    padding: 26px 20px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    gap: 14px;
  `;
  const Image = styled.img`
    width: 100%;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    background-color: ${({ theme }) => theme.white};
    box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.3);
  `;
  const Tags = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
    align-items: center;
  `;
  const Tag = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primary + 15};
    border-radius: 10px;
    padding: 2px 10px;
  `;
  const Details = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0px 2px;
  `;
  const CardTitle = styled.h2`
    color: ${({ theme }) => theme.primary};
  `;
  const CardDescription = styled.div`
    font-weight: 400;
    color: ${({ theme }) => theme.white};
    overflow: hidden;
    margin-top: 8px;
    max-width: 100%;
    flex: 1;
  `;
  const Span = styled.span`
    font-weight: 400;
    color: ${({ theme }) => theme.primary};
  `;

  const LivePreviewButton = styled.a`
    width: 100%;
    max-width: 280px;
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
    text-align: center;
    padding: 6px 12px;
    margin: 10px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 300ms;
    text-decoration: none;
    &:hover {
      background-color: ${({ theme }) => theme.primary + 99};
    }
  `;

  const [isReadMore, setIsReadMore] = useState(false);
  const getDescription = (description = "", size = 20) => {
    let shortDescription = "";
    for (let index = 0; index < size; index++) {
      shortDescription = shortDescription + description[index];
    }
    return shortDescription;
  };
  return (
    <Card key={index}>
      <Image src={project.image} />
      <Tags>
        {project.tags.map((tag) => {
          return <Tag key={tag}>{tag}</Tag>;
        })}
      </Tags>
      <Details>
        <CardTitle>{project.title}</CardTitle>
        <CardDescription>
          {isReadMore ? (
            <>
              {project.description}
              <Span
                style={{ color: "cyan" }}
                onClick={() => setIsReadMore(false)}
              >
                Read Less
              </Span>
            </>
          ) : (
            <>
              {getDescription(project.description, 200)}
              {"  "}
              <Span onClick={() => setIsReadMore(true)}>Read More ...</Span>
            </>
          )}
        </CardDescription>
        <LivePreviewButton href={project.webapp} target="_blank">
          see live
        </LivePreviewButton>
      </Details>
    </Card>
  );
};

export default SingleCard;
