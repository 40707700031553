import React from "react";
import { skills } from "../../data/constants";
import {
  Container,
  Wrapper,
  Title,
  Description,
  SkillsContainer,
  Skill,
  SkillImage,
  SkillItem,
  SkillList,
  SkillTitle,
} from "./styledComponents";

const Skills = () => {
  return (
    <div id="skills">
      <Container>
        <Wrapper>
          <Title>Skills</Title>
          <Description>
            Here are some of my Skills on which I have been working on for the
            past 3 years.
          </Description>
          <SkillsContainer>
            {skills.map((item) => {
              return (
                <Skill>
                  <SkillTitle>{item.title}</SkillTitle>
                  <SkillList>
                    {item.skills.map((skill) => {
                      return (
                        <SkillItem>
                          <SkillImage src={skill.image} />
                          {skill.name}
                        </SkillItem>
                      );
                    })}
                  </SkillList>
                </Skill>
              );
            })}
          </SkillsContainer>
        </Wrapper>
      </Container>
    </div>
  );
};

export default Skills;
