import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  Container,
  Wrapper,
  Title,
  Description,
  TimelineSection,
} from "./styledComponents";
import { experiences } from "../../data/constants";
import ExperienceCard from "../cards/experienceCard";

const Experience = () => {
  return (
    <Container id="experience">
      <Wrapper>
        <Title>Experience | 3+ Years</Title>
        <Description>Here are some companies where I have been working for the last 3 years.</Description>
        <TimelineSection>
          <Timeline>
            {experiences.map((experience, index) => {
              return (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="secondary" />
                    <TimelineConnector />
                  </TimelineSeparator>  
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <ExperienceCard experience={experience} />
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </TimelineSection>
      </Wrapper>
    </Container>
  );
};

export default Experience;
