import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import { darkTheme, lightTheme } from "./utils/themes";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/Navbar/index";
import Skills from "./components/Skills/index";
import Hero from "./components/HeroSection";
import Education from "./components/education";
import Experience from "./components/experience";
import Projects from "./components/myprojects";
import Contact from "./components/contact";
import Footer from "./components/footer";
import Certifications from "./components/certifications";

const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
  height: 100%;
`;

const Wrapper = styled.div`
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`;
function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Navbar />
        <Body>
          <Hero />
          <Wrapper>
            <Skills />
            <Experience />
          </Wrapper>
          <Projects />
          <Wrapper>
          <Certifications />
            <Education />
            <Contact />
          </Wrapper>
          <Footer />
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;
