import React from "react";
import {
  Container,
  Description,
  TimelineSection,
  Title,
  Wrapper,
} from "./styledComponents";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import CertificationCard from "../cards/certificationCard";
import { certification } from "../../data/constants";

const Certifications = () => {
  return (
    <Container id="certifications">
      <Wrapper>
        <Title>
          <span style={{ fontSize: 45 }}>C</span>ertification
        </Title>
        <Description>
          Here are the certificates I have achieved throughout my professional
          life, and I will continue to earn more in the future.
        </Description>
        <TimelineSection>
          <Timeline>
            {certification.map((certification, index) => {
              return (
                <TimelineItem>
                  <TimelineContent>
                    <CertificationCard
                      certification={certification}
                      key={index}
                    />
                  </TimelineContent>
                  <TimelineSeparator>
                    <TimelineDot variant="filled" color="secondary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                </TimelineItem>
              );
            })}
          </Timeline>
        </TimelineSection>
      </Wrapper>
    </Container>
  );
};

export default Certifications;
