import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { Bio } from "../../data/constants";
import {
  FooterContainer,
  FooterWrapper,
  Nav,
  NavLink,
  SocialMediaIcon,
  SocialMediaIcons,
  Copyright,
  Logo,
} from "./styledComponents";

const Footer = () => {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  const handleEmailClick = () => {
    window.location.href = `mailto:${Bio.email}`;
  };
  return (
    <FooterContainer>
      <FooterWrapper>
        <Logo>Abdul Rehman</Logo>
        <Nav>
          <NavLink href="#about">About</NavLink>
          <NavLink href="#skills">Skills</NavLink>
          <NavLink href="#experience">Experience</NavLink>
          <NavLink href="#projects">Projects</NavLink>
          <NavLink href="#education">Education</NavLink>
        </Nav>
        <SocialMediaIcons>
          <SocialMediaIcon href={Bio.linkedin} target="_blank">
            <LinkedInIcon />
          </SocialMediaIcon>
          <SocialMediaIcon href={Bio.github} target="_blank">
            <GitHubIcon />
          </SocialMediaIcon>
          <SocialMediaIcon onClick={handleEmailClick} target="_blank">
            <EmailIcon />
          </SocialMediaIcon>
        </SocialMediaIcons>
        <Copyright>
          &copy; {currentYear} Abdul Rehman. All rights reserved.
        </Copyright>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
