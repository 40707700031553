import React from "react";
import {
  Container,
  Description,
  Title,
  Wrapper,
  ContactLine,
  ContactContainer,
  ContactMail,
} from "./styledComponents";

const Contact = () => {
  return (
    <Container id="contact">
      <Wrapper>
        <Title>Contact</Title>
        <Description>
          Feel free to reach out to me for any questions or opportunities.
        </Description>
        <ContactContainer>
          <ContactLine>For any question please mail me :</ContactLine>
          <ContactMail>arehmana377@gmail.com</ContactMail>
        </ContactContainer>
      </Wrapper>
    </Container>
  );
};

export default Contact;
