import React from "react";
import styled from "styled-components";

const EducationCard = ({ education }) => {
  const Card = styled.div`
  width: 650px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 12px 16px;
  justify-content: space-between;
  position: relative;
  overflow : hidden
  display: flex;
  flex-direction : column;  
  gap : 12px;
  transition : all 0.3s ease-in-out;
  & :hover{
    box-shadow : 0 , 0 , 20px rgba(0 , 0 , 0 ,0.2);
    cursor : pointer
    transform : translateY(-8px);
  }
  @media (max-width: 768px){
    padding : 10px;
    gap : 8px;
    width : 300px;
  }
 border: 0.1px solid #306EE8;
 box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
`;

  const Top = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
  `;

  const Logo = styled.img`
    height: 50px;
    border-radius: 10px;
    margin-top: 4px;
    @media (max-width: 786px) {
      height: 40px;
    }
  `;

  const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;
  const School = styled.div`
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.primary };
    @media (max-width: 786px) {
      font-size: 14px;
    }
  `;
  const Degree = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_primary};
    @media (max-width: 786px) {
      font-size: 12px;
    }
  `;
  const Duration = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_primary};
    @media (max-width: 786px) {
      font-size: 10px;
    }
  `;
  const Description = styled.div`
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_primary};
    margin-bottom: 10px;
    @media (max-width: 786px) {
      font-size: 12px;
    }
  `;
  const Span = styled.span`
    overflow: hidden;
    margin-top: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 100%;
    text-overflow: ellipsis;
  `;
  const Grade = styled.div`
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    color: ${({ theme }) => theme.text_primary };
    @media (max-width: 786px) {
      font-size: 12px;
    }
  `;

  return (
    <Card>
      <Top>
        <Logo src={education.img} />
        <Body>
          <School>{education.school}</School>
          <Degree>{education.degree}</Degree>
          <Duration>{education.date}</Duration>
        </Body>
      </Top>
      <Grade>Grades : {education.grade}</Grade>
      <Description>
        <Span>{education.desc}</Span>
      </Description>
    </Card>
  );
};

export default EducationCard;
