import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { education } from "../../data/constants";
import {
  Container,
  Wrapper,
  Title,
  Description,
  TimelineSection,
} from "./styledComponents";
import EducationCard from "../cards/educationCard";

const Education = () => {
  return (
    <Container id="education">
      <Wrapper>
        <Title>Education</Title>
        <Description>
          My education has been a journey of self-discovery and growth. My
          educational details are as follows.
        </Description>
        <TimelineSection>
          <Timeline>
            {education.map((education, index) => {
              return (
                <TimelineItem>
                  <TimelineContent>
                    <EducationCard education={education} key={index} />
                  </TimelineContent>
                  <TimelineSeparator>
                    <TimelineDot variant="filled" color="secondary" />
                    <TimelineConnector />
                  </TimelineSeparator>
                </TimelineItem>
              );
            })}
          </Timeline>
        </TimelineSection>
      </Wrapper>
    </Container>
  );
};

export default Education;
